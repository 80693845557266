.third-section-outer-container {
    padding-top: 60px;
    padding-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
    
}

.third-section-inner-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  
}

.third-section-bg-image {
  background-image: url("/public/made-for-gamers-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.web-video {
  width: 100%;
  height: auto;
}

/* Small devices (phones 576px and down) */
@media only screen and (max-width: 576px) {

  .third-section-inner-container {
    padding: 0 20px;
  }


}

/* Small devices (portrait tablets and large phones, between 576px and 768px) */
@media only screen and (min-width: 576px) and (max-width: 768px) {

  .third-section-inner-container {
    padding: 0 60px;
  }

}