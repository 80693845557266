.fourth-section-outer-container {
    padding-top: 60px;
    padding-bottom: 60px;
    
}

.fourth-section-inner-container {
  display: flex;
  justify-content: center;
}

.banner-heading {
  font-weight: 700;
  font-size: 18.72px;
  background: -webkit-linear-gradient(left, #c18794 , #492833);
   background: -o-linear-gradient(right, #c18794, #492833);
   background: -moz-linear-gradient(right, #c18794, #492833);
   background: linear-gradient(to right, #c18794, #492833); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

/* Small devices (phones 576px and down) */
@media only screen and (max-width: 576px) {
  .fourth-section-inner-container {
    padding: 0 20px;
  }

}

/* Small devices (portrait tablets and large phones, between 576px and 768px) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .fourth-section-inner-container {
    padding: 0 60px;
  }

}