.second-section-outer-container {
    padding-top: 60px;
    padding-bottom: 60px;
    
    margin-left: auto;
    margin-right: auto;
}

.second-section-inner-container {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    padding-right: 60px;
}

.second-section-box-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-5deg);
}

.task-image {
  width: 375px;
  height: auto;
}



.second-section-box-2 {
    border-radius: 20px;
  background: 
    linear-gradient(to right, black, black), 
    linear-gradient(to right, rgba(193,135,148,1) , rgba(73,40,51,1)); 
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
    border: 2px solid transparent;
    padding: 60px;
}



.box-heading {
  color: white;
  font-weight: 900;
  font-size: 24px;

}

.box-text {
  color: white;
  font-weight: 400;
  font-size: 16px;
  
}

/* Small devices (phones 576px and down) */
@media only screen and (max-width: 576px) {
  .second-section-outer-container {
    margin-top: -180px;
  }

  .second-section-inner-container {
    grid-template-columns: 1fr;
    
    padding: 0 20px;
  }

  .second-section-box-1 {
    display: none;
  }

  .second-section-box-2 {
    padding: 20px;
  }

}

/* Small devices (portrait tablets and large phones, between 576px and 768px) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .second-section-outer-container {
    margin-top: -180px;
  }

  .second-section-inner-container {
    grid-template-columns: 1fr;
    
    padding: 0 60px;
  }

  .second-section-box-1 {
    display: none;
  }

  .second-section-box-2 {
    padding: 20px;
  }

}