.image-section-outer-container {
  padding-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
}


.image-section-image {
  width: 100%;
  height: auto;
  margin-top: -120px;
}

/* Small devices (phones 576px and down) */
@media only screen and (max-width: 576px) {
  .image-section-outer-container {
    display: none;
  }
}

/* Small devices (phones 576px and down) */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .image-section-outer-container {
    display: none;
  }
  }